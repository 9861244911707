import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { createMessage, getMessage, updateMessage } from '../redux/actions'

import Audio from 'util/modules/Audio/Audio'
import Attachment from 'util/modules/Audio/Attachment'
import messageVariables from 'panel/_root/configs/message.variables.json'
import { formatDate } from 'util/masks'
import Upload, { UploadRef } from 'util/modules/Upload'

const initialState = { shortcut: '/', message: '', reference: '' }

const MessageDatabaseForm: React.FC<{ onClose (): void, type: 'script' | 'message' }> = ({ onClose, type }) => {
  const [isFetching, setFetching] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [step, setStep] = useState<'message' | 'script'>('message')
  const [references, setReferences] = useState<string[]>([])
  const [reference, setReference] = useState<string>('')

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState(initialState)

  const [file, setFile] = useState<File>()
  const [attachmentUrl, setAttachmentUrl] = useState('')
  const [attachmentType, setAttachmentType] = useState<'PICTURE' | 'FILE' | 'AUDIO'>()

  const uploadRef = useRef<UploadRef>(null)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { message } = useSelector((state: ApplicationState) => state.messageDataBaseReducer)
  const { messages } = useSelector((state: ApplicationState) => state.messageDataBaseReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/message-database')
  }, [history])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected) return undefined

    const formData = new FormData()
    formData.set('shortcut', form.shortcut)
    formData.set('message', form.message)
    formData.set('reference', form.reference)
    formData.set('references', references.join(','))
    if (file) formData.set('attachment', file)

    setFetching(true)
    if (id) {
      await updateMessage(selected, id, formData)(dispatch)
    } else {
      await createMessage(selected, formData)(dispatch).then((res) => {
        if (res?.data) {
          handleClear()
          onClose()
        }
      })
    }
    setFetching(false)

    if (isFetching) {
      onClose()
    }
  }

  function handleSelectAttachment (file?: File) {
    if (file) {
      setFile(file)
      const fReader = new FileReader()

      fReader.onload = (e) => {
        setAttachmentUrl(e.target?.result?.toString() || '')
      }
      fReader.readAsDataURL(file)
    } else {
      setFile(undefined)
      setAttachmentUrl('')
    }
  }

  useEffect(() => {
    if (selected) {
      if (id) {
        getMessage(selected, id)(dispatch).then(res => {
          const messageDatabase = res?.data
          if (res?.status === 'success' && messageDatabase) {
            setForm({
              shortcut: messageDatabase.shortcut,
              message: messageDatabase.message,
              reference: messageDatabase.reference
            })

            setAttachmentType(messageDatabase.attachmentType)
            if (messageDatabase.attachmentUrl) {
              setAttachmentUrl(messageDatabase.attachmentUrl)
              if (messageDatabase.attachmentType === 'PICTURE') {
                uploadRef.current?.setPreviewImage(messageDatabase.attachmentUrl)
              }
            } else {
              setAttachmentUrl('')
              uploadRef.current?.setPreviewImage('')
            }
          }
          setReference(messageDatabase?.reference || '')
          setReferences(messageDatabase?.references.split(',') || [])
        })
      } else {
        handleClear()
      }
    }
  }, [id, selected, dispatch, handleClear])

  useEffect(() => {
    if (type) {
      setStep(type)
    }
  }, [type])

  return (
    <div className="panel no-padding">
      <ModalHeader title="Configurações de Mensagens" onClose={onClose} />
      <div className="panel-body">
        <div className='row'>
          <button
            className={`${step.includes('message') ? 'primary' : ''}`}
            type='button'
            style={{ flex: 1, borderRadius: 0, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
            onClick={() => setStep('message')}
          >
            Mensagen
          </button>
          <button
            type='button'
            style={{ flex: 1, borderRadius: 0, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
            onClick={() => setStep('script')}
            className={`${step.includes('script') ? 'primary' : ''}`}
          >
            Funil
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {step.includes('message') ? (
            <>
              <div>
                <label>Atalho de Mensagem</label>
                <input
                  required
                  type="text"
                  value={form.shortcut}
                  onChange={(e): void => setForm({
                    ...form,
                    shortcut: `/${e.target.value.replace(/^[/]/g, '')}`
                  })}
                />
              </div>

              <div>
                <label>Título de referência</label>
                <input
                  required
                  type="text"
                  value={form.reference}
                  onChange={(e): void => setForm({ ...form, reference: e.target.value })}
                />
              </div>

              <div className="margin-tb-16">
                <label>Mensagem</label>
                <textarea
                  rows={6}
                  value={form.message}
                  onChange={(e): void => setForm({ ...form, message: e.target.value })}
                />
              </div>

              <div className="margin-tb-16">
                <label>Anexar Arquivo?</label>
                <select
                  value={attachmentType}
                  onChange={(e): void => {
                    if (e.target.value) {
                      setFile(undefined)
                      setAttachmentUrl('')
                      setAttachmentType(e.target.value as 'FILE')
                    }
                  }}
                >
                  <option value={undefined}>Nenhum</option>
                  <option value="AUDIO">Áudio</option>
                  <option value="PICTURE">Imagem</option>
                  <option value="FILE">Arquivo</option>
                </select>
              </div>
              {
                attachmentType === 'AUDIO' &&
              <div className="form-control">
                <label>Anexar Áudio</label>
                <div className="row input" style={{ height: 72 }}>
                  <Audio onRecord={handleSelectAttachment} />
                  <Attachment
                    acceptedMimeTypes={['audio/ogg', 'audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg']}
                    onSelect={handleSelectAttachment}
                  />
                  {
                    !!attachmentUrl &&
                    <audio controls style={{ flexGrow: 1 }}>
                      <source src={attachmentUrl} />
                    </audio>
                  }
                </div>
              </div>
              }

              {
                attachmentType === 'FILE' && (
                  <div className="form-control">
                    <label>Anexar Arquivo</label>
                    <div className="row input" style={{ height: 72 }}>
                      <Attachment
                        acceptedMimeTypes={['application/pdf']}
                        onSelect={handleSelectAttachment}
                      />
                      {
                        !!attachmentUrl &&
                      <div className="grow padding-left-16">
                        <div className="row">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="bold"
                            href={attachmentUrl}
                            style={{ overflow: 'hidden', height: 19, width: '100%' }}
                          >
                            {file?.name || message?.attachmentUrl?.split('/').pop()}
                          </a>
                          <p>
                            {file?.type || `image/${message?.attachmentUrl?.split('.').pop() || 'jpeg'}`}
                            &nbsp;-&nbsp;
                            {formatDate(file ? (new Date(file.lastModified)).toISOString() : message?.updatedAt)}
                          </p>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                )
              }

              <div
                className="form-control"
                style={{ display: attachmentType === 'PICTURE' ? 'inherit' : 'none' }}
              >
                <label>Anexar Imagem</label>
                <div className="row input" style={{ height: 72 }}>
                  <div className="attachment_picture" style={{ width: 50, height: 50 }}>
                    <Upload ref={uploadRef} placeholder={false} onChange={handleSelectAttachment} />
                  </div>
                  <div className="grow padding-left-16">
                    <div className="row">
                      <p className="bold" style={{ overflow: 'hidden', height: 19 }}>
                        {file?.name || message?.attachmentUrl?.split('/').pop()}
                      </p>
                      <p>
                        {file?.type || `image/${message?.attachmentUrl?.split('.').pop() || 'jpeg'}`}
                      &nbsp;-&nbsp;
                        {formatDate(file ? (new Date(file.lastModified)).toISOString() : message?.updatedAt)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="margin-top-16 bold">Variáveis disponíveis:</p>
              <div className="row justify-start margin-bottom-16 margin-top-8">
                {messageVariables.map(variable => `{${variable}}`).join(', ')}
              </div>
            </>
          ) : null}

          {step.includes('script') ? (
            <div>

              <div
                style={{
                  background: '#e2f4ff',
                  border: '1px solid cyan',
                  padding: 12,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                  marginTop: 24,
                  borderRadius: 8
                }}
              >
                <span
                  className='gradient'
                  style={{
                    height: 20,
                    width: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 100
                  }}>
                  <i className="fas fa-info" />
                </span>
              Um script é um conjunto de mensagens agrupadas que são enviadas em
              sequência para seu cliente com apenas um clique!
              </div>
              <div>

                <div className='row' style={{ gap: 10, alignItems: 'flex-end' }}>
                  <div style={{ flex: 1 }}>
                    <label>Sequência de mensagem</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <button type='button' style={{
                        cursor: 'auto',
                        width: 150,
                        height: '100%',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                      }}>
                        Mensagem
                      </button>

                      <select
                        value={reference}
                        onChange={(e): void => {
                          if (e.target.value) setReference(e.target.value)
                        }}
                      >
                        <option value={undefined}>Nenhum</option>

                        {messages.map((item) => {
                          return <option key={item.id} value={item.reference}>{item.reference}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  <button
                    type='button'
                    className='primary'
                    style={{ height: 48 }}
                    onClick={() => setReferences([...references, reference])}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>

                <div className='margin-top-4'>
                  {references.map((item, index) => {
                    return (
                      <div className='row' style={{ gap: 10, alignItems: 'flex-end' }} key={index}>
                        <div style={{ flex: 1 }}>
                          <label>{index + 1}. Sequência de mensagem</label>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button type='button' style={{
                              cursor: 'auto',
                              width: 150,
                              height: '100%',
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }}>
                            Mensagem
                            </button>

                            <select value={item} disabled>
                              <option>Nenhum</option>

                              {messages.map((item) => {
                                return <option key={item.id} value={item.reference}>{item.reference}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        <button
                          type='button'
                          className='danger'
                          style={{ height: 48 }}
                          onClick={() => {
                            const newReferences = references.filter((_, i) => i !== index)
                            if (window.confirm('Realmente deseja remover essa referência?')) {
                              setReferences(newReferences)
                            }
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>
          ) : null}

          <div className="row margin-top-16 buttons">
            <span className="secondary button" onClick={handleClear}>Limpar</span>
            <button className="gradient button" disabled={isFetching}>
              {isFetching ? 'Salvando...' : 'Salvar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MessageDatabaseForm
