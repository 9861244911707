import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import './styles/MessageBase.scss'

import Modal from 'util/modules/Modal'
import MessageDatabaseForm from './components/MessageDatabaseForm'
import MessageDatabaseList from './components/MessageDatabaseList'
import { hasPermission } from 'util/services/AuthService'

const MessageDatabase: React.FC = () => {
  const history = useHistory()
  const [isFormOpen, setFormOpen] = useState(false)
  const [step, setStep] = useState<'message' | 'script'>('message')

  const { action } = useParams<{ [x: string]: string }>()

  function handleCloseForm () {
    setFormOpen(false)
    history.push('/panel/message-database')
  }

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  return (
    <div className="row panel bordered message-base-container">
      <div className="row padding-24">
        <p className="grow" style={{ fontSize: 18 }}>Mensagens</p>
        <button
          className="radius margin-right-8"
          style={{ background: 'var(--primary)', color: 'white' }}
          onClick={() => {
            setStep('script')
            setFormOpen(true)
          }}>
        Mensagem  & Script
        </button>
        {
          hasPermission('MESSAGE_DATABASE:CREATE') &&
          <button className="gradient radius" style={{ width: 120 }} onClick={() => {
            setStep('message')
            setFormOpen(true)
          }}>
            Cadastrar
          </button>
        }
      </div>
      <MessageDatabaseList />
      <Modal isOpen={isFormOpen} onClose={handleCloseForm} >
        <MessageDatabaseForm onClose={handleCloseForm} type={step} />
      </Modal>
    </div>
  )
}

export default MessageDatabase
