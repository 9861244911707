import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, NavLink } from 'react-router-dom'

import { deleteMessage } from '../redux/actions'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const MessageDatabaseList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteId, setDeleteId] = useState<number>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { messages } = useSelector((state: ApplicationState) => state.messageDataBaseReducer)

  function handleRemove (id: number):void {
    if (selected) {
      deleteMessage(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/message-database')
        }
      })
    }
  }

  return (
    <div className="list">
      <div className="list-header">
        <div>Id</div>
        <div>Atalho</div>
        <div>Título de referência</div>
        <div>Referencias</div>
        <div>Mensagens</div>
        <div>Criado em</div>
        <div>Atualizado em</div>
        <div>Ações</div>
      </div>

      {
        messages.length === 0 &&
        <div className="list-row empty">
          <i className="fas fa-comment-alt" style={{ fontSize: 90, padding: '24px 0' }} />
          <span className="grid-span" style={{ height: 0 }}>Nenhuma mensagem cadastrada ainda...</span>
        </div>
      }

      {
        messages.map((message, i) => (
          <div className="list-row" key={i}>
            <div data-content="Id" className="bold">#{message.id}</div>
            <div data-content="Atalho">{message.shortcut}</div>
            <div data-content="Referência">{message.reference}</div>
            <div data-content="Referencias">{message.references}</div>
            <div
              data-content="Mensagens"
              className="text-overflow-box"
              dangerouslySetInnerHTML={{
                __html: message.message
                  .replace(/[*]([^*]+)[*]/g, '<b>$1</b>')
                  .replace(/\n/g, '<br />')
                  .replace(/\r/g, '<br />')
              }}
            />
            <div data-content="Criado em">{formatDateTime(message.createdAt)}</div>
            <div data-content="Atualizado em">{formatDateTime(message.updatedAt)}</div>
            <div data-content="Ações" className="row buttons-list-row">
              <div className="row">
                {
                  hasPermission('MESSAGE_DATABASE:UPDATE') &&
                  <NavLink to={`/panel/message-database/${message.id}/edit`} className="button">
                    <i className="fas fa-edit" /> &nbsp;Editar
                  </NavLink>
                }
                {
                  hasPermission('MESSAGE_DATABASE:DELETE') &&
                  <span className="button" onClick={() => setDeleteId(message.id)}>
                    <i className="fas fa-trash-alt" /> &nbsp;Excluir
                  </span>
                }
              </div>
            </div>
          </div>
        ))
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default MessageDatabaseList
